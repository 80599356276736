@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  height: 100%;
}

body::-webkit-scrollbar {
  width: 5px;
  background: #8853a11a;
  box-shadow: none;
  z-index: 9999;
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(79, 70, 229);
  height: 10px;
  border-radius: 100px;
  box-shadow: none;
}

body::-webkit-scrollbar-track {
  display: none;
}

body::-webkit-scrollbar-track-piece {
  box-shadow: none;
  background: #fafafa;
}
